'use client'

import { Divider } from 'antd'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { switchLanguage } from '@/i18n'
import { cn } from '@/utils/clsx'

const languages = [
  {
    code: 'en',
    name: 'ENG',
  },
  {
    code: 'slo',
    name: 'SLO',
  },
]

interface LanguageSwitchProps {
  className?: string
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ className }) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  return (
    <div className={cn('flex items-center text-sm', className)}>
      {languages.map(({ code, name }, index) => (
        <Fragment key={index}>
          <div
            onClick={() => switchLanguage(code)}
            className={cn(
              'cursor-pointer',
              currentLanguage === code ? 'font-bold' : 'opacity-60'
            )}
          >
            {name}
          </div>
          {index < languages.length - 1 && (
            <Divider
              type='vertical'
              className='bg-on-surface/40 dark:bg-dark-on-surface/40'
            />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default LanguageSwitch
